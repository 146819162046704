<template>
    <div>
	<br/>
        <div class="row" v-if="!rule_operator_simple">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <b-form inline>
                                    <router-link v-if="rule_show_order_button" type="button" style="padding-left: 10px" class="btn btn-success" to="/orders/hopshop">{{ $t('orders.nav.new_order_hopshop')}}</router-link>&nbsp;
                                    <router-link v-if="rule_show_order_button" type="button" class="btn" style="background-color: #76367e; padding-left: 10px;color: white" to="/orders/lots">{{ $t('orders.nav.new_order_broadcast')}}</router-link>
                                    <router-link v-if="rule_order_search" type="button" class="btn btn-success" style="margin-left: 10px" to="/orders/search">{{ $t('orders.nav.search_for_orders')}}</router-link>
                                    <b-button v-if="rule_order_create_ticket" variant="danger" style="margin-left: 10px" @click="showModalTicket()">{{ $t('orders.nav.create_ticket')}}</b-button>
                                    <b-form-select v-model="filter" :options="so.option" style="margin-left: 20px" @change="changeFilter()"></b-form-select>
                                    <b-button variant="info" @click="loadData()" style="margin-left: 10px"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></b-button>
                                </b-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <br/>
      <div class="row">
        <table class="table" v-if="!rule_operator_simple">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('main.number_order')}}</th>
            <th scope="col">{{ $t('main.date_order')}}</th>
            <th scope="col">{{ $t('main.customer')}}</th>
            <th scope="col">{{ $t('main.phone')}}</th>
            <th scope="col">{{ $t('main.status_order')}}</th>
            <th scope="col">{{ $t('main.operations')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in orders" :key="item.id">
            <td>{{index + 1}}</td>
            <td> {{item.number}}</td>
            <td>{{item.ctime}}</td>
            <td>{{item.client}}</td>
            <td>{{item.phone}}</td>
            <td>{{item.status}}</td>
              <td>
                  <button class="btn btn-sm btn-success"  @click="showOrder(item)" :disabled="!item.edit">
                      <b-icon-pencil-square></b-icon-pencil-square>
                  </button>
                <button class="btn btn-sm btn-info ml-1"  @click="showModalHistory(item.etherid+'-'+item.id)">
                  <b-icon-clock-history></b-icon-clock-history>
                </button>
              </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Axios from 'axios';
    import bus from "@/bus";
    import HelpdeskModal from "@/components/helpdeskModal.vue";

export default {
  name: 'Orders',

  components: {
    HelpdeskModal,

  },

  data() {
        return {
            orders: [],
            filter: 'return',
            so: {
                option: [
                    {value: 'return', text: this.$t('orders.variable.return_for_revision') },
                    {value: 'all', text: this.$t('orders.variable.all_orders') }
                ]
            }
        }
    },

  metaInfo() {
    return {
        title: this.$t('orders.title')
    }
  },

  mounted() {
    this.loadData();
    bus.$emit('set-header', this.$t('orders.title'));
  },

  methods: {
    loadData() {                  // загрузка данных с backend
      Axios
          .get(this.prefix_url + '/api/order?ts=' + Date.now()+'&filter='+this.filter)
          .then(response => {
            console.log(response);
            this.orders = response.data.orders;
          });
    },

    showOrder(data) {
        bus.$emit('portal:order:open', {
            ether_id: data.etherid,
            order_id: data.id
        });
    },

    showModalTicket () {
      bus.$emit('Tickets::append', {
        type: "service"
      });
    },

    changeFilter(){
        this.loadData();
    },



    showModalHistory(data) {
      bus.$emit('portal:order:history:open', data);
//      this.$bvModal.show('modal-history');
    },

  },

  computed : {
    rule_order_hopshop: function (){return this.$store.getters.getRule('order') },
    rule_order_zargar: function (){return this.$store.getters.getRule('orderzargar') },
    rule_operator_simple: function (){return this.$store.getters.getRule('operator_simple') },
    rule_order_lots: function (){return this.$store.getters.getRule('orderlots') },
    rule_order_search: function (){return this.$store.getters.getRule('orderSearch') },
    rule_order_create_ticket: function (){return this.$store.getters.getRule('ticketCreate') },
    rule_show_order_button: function (){return this.$store.getters.getRule('show_order_button') },
    current_phone: {
          get () { return this.$store.getters.getCurrentPhone; },
          set(value) { this.$store.dispatch('setCurrentPhone', value); }
    },
    prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
  }
}
</script>