<template>
    <div>
        <br/>
        <b-card>
            <div class="row">
                <div class="col-sm-6">
                    <button class="btn btn-info" @click="loadData()" v-b-tooltip.hover title="Reload data" style="margin-left: 10px"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></button>
<!--                    <button :class="{btn: true,  'btn-primary': !mode_union, 'btn-danger': mode_union}" @click="changeModeUnion()" v-b-tooltip.hover title="Union orders" style="margin-left: 10px"><b-icon-union></b-icon-union></button>-->

                  <button :class="{btn: true, 'btn-primary': !show_filter, 'btn-danger': show_filter}" @click="show_filter = ! show_filter" style="margin-left: 5px" v-b-tooltip.hover title="Filters">
                        <template v-if="!show_filter">
                            <b-icon-funnel></b-icon-funnel>
                        </template>
                        <template v-else>
                            <b-icon-funnel-fill></b-icon-funnel-fill>
                        </template>
                    </button>
                    <b-button v-if="loading" variant="primary" disabled style="margin-left: 10px">
                        <b-spinner small type="grow"></b-spinner>
                        {{$t('main.please_wait_data_is_loading')}}
                    </b-button>
                </div>
                <template>
                <div class="col-sm-3">
                    <h2>{{$t('main.new_orders')}}:
                        <template v-if="new_order > 100">
                            <span class="text-danger">{{new_order}}</span>
                        </template>
                        <template v-else>
                            <span class="text-success">{{new_order}}</span>
                        </template>
                    </h2>
                </div>
                <div class="col-sm-3">
                    <h2>{{$t('main.missed_call_opp')}}:
                        <template v-if="missed_order > 100">
                            <span class="text-danger">{{missed_order}}</span>
                        </template>
                        <template v-else>
                            <span class="text-success">{{missed_order}}</span>
                        </template>
                    </h2>
                </div>
                </template>
<!--                <template v-else>-->
<!--                    <div class="col-sm-3">-->
<!--                        <h2>Select {{items_union.length}} orders</h2>-->
<!--                    </div>-->
<!--                    <div class="col-sm-3">-->
<!--                        <b-button v-if="items_union.length > 1" variant="primary">Объеденить заказы</b-button>-->
<!--                    </div>-->
<!--                </template>-->

            </div>
        </b-card>
        <b-card v-if="show_filter" style="margin-top: 10px">
            <table width="100%">
                <tbody>
                <tr>
                    <th class="text-center">{{$t('main.period')}}</th>
                    <th class="text-center">{{$t('main.search_by_order_number2')}}</th>
                    <th class="text-center">{{$t('main.operator')}}</th>
                    <th class="text-center">{{$t('main.operator_opp')}}</th>

                    <th class="text-center">{{$t('main.status')}}</th>
                    <th></th>
                </tr>
                <tr>
                    <td>
                        <b-form-select v-model="filter.s_filter_time" :options="so.options_time" @change="changeFilter()"></b-form-select>
                    </td>
                    <td>
                        <b-form-input v-model="filter.s_filter_order_id"  @change="changeFilter()"></b-form-input>
                    </td>
                    <td>
                        <b-form-select v-model="filter.s_filter_owner" :options="so.options_owners"  @change="changeFilter()"></b-form-select>
                    </td>
                    <td>
                        <b-form-select v-model="filter.s_filter_operator" :options="so.options_operators"  @change="changeFilter()"></b-form-select>
                    </td>

                    <td>
                        <b-form-select v-model="filter.s_filter_status" :options="so.options_status"  @change="changeFilter()"></b-form-select>
                    </td>
                    <td>
                        <button class="btn btn-danger"  @click="resetFilter()"   :disabled="filter.s_filter_needs_improvement === false && filter.s_filter_time == 'today' && filter.s_filter_order_id == '' && filter.s_filter_status=='new-order' && filter.s_filter_owner=='' && filter.s_filter_operator=='' && filter.s_filter_product=='' && regions.length == 0">{{$t('main.reset_filter')}}</button>
                    </td>
                </tr>
                <tr>
                    <th class="text-center">{{$t('main.regions')}}</th>
                    <th class="text-center">{{$t('main.product')}}</th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th></th>
                </tr>
                <tr>
                    <td>
                        <RegionForm
                                :title="rnames"
                                :items="regions"
                                @addProduct="addRegion"
                                @delProduct="delRegion"
                        >
                        </RegionForm>
                    </td>
                    <td>
                        <ProductOneForm
                            @select="setFilterProduct"
                            :title ="product_title"
                        >

                        </ProductOneForm>
                    </td>
                    <td>

                        <b-form-checkbox v-model="filter.s_filter_needs_improvement" @change="changeFilter()" switch>
                            {{$t('main.needs_improvement')}}
                        </b-form-checkbox>
                    </td>
                    <td>

                    </td>

                    <td>

                    </td>
                    <td>

                    </td>
                </tr>
                </tbody>
            </table>
        </b-card>

        <template v-if="filter.panel_datepick">
            <br/>
            <b-card>
                <div class="row" v-if="filter.panel_datepick">
                    <div class="col-sm-6">
                        <label class="mr-sm-2" for="inline-form-custom-select-pref">{{$t('main.beginning_of_period')}}: </label>
                        <b-form-datepicker
                                v-model="filter.date_min_value"
                                size="sm"
                                class="mb-2"
                                style="margin-right: 20px"
                                @input = "loadData()"
                                ></b-form-datepicker>
                    </div>
                    <div class="col-sm-6">
                        <label class="mr-sm-2" for="inline-form-custom-select-pref">{{$t('main.end_of_period')}}: </label>
                        <b-form-datepicker
                                v-model="filter.date_max_value"
                                size="sm"
                                class="mb-2"
                                @input = "loadData()"
                                ></b-form-datepicker>
                    </div>
                </div>
            </b-card>
        </template>
        <br/>
        <b-overlay :show="loading" rounded="sm">
            <b-row>
                <b-col cols="4">
                    <template v-if="pageCount > 1">
                        <b-pagination-nav
                                v-model="pageNumber"
                                :number-of-pages="pageCount"
                                base-url="#"
                                @change="goPage"
                                first-number
                                last-number
                                align="fill"
                                >
                        </b-pagination-nav>
                    </template>
                </b-col>

                <b-col style="padding-top: 5px;" class="text-left" cols="8">
                    <span style="font-size: 80%">{{$t('main.paging_show', {c: orders.length, from: filtredDataCount})}}</span>
                </b-col>
            </b-row>
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">

<!--                      <input type="checkbox" @change="addSelectItem(null, $event)" />-->
                      <b-form-checkbox
                          @change="addSelectItem(null, $event)"
                      />
                    </th>
<!--                    <th v-if="mode_union" scope="col"></th>-->
                    <th scope="col"><a href="#" @click="sortcol('number')">{{$t('main.number_order')}}</a>
                        <template v-if="filter.sort_col == 'number'">
                            <template v-if="filter.sort_direct == 'asc'">
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </template>
                            <template v-else>
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </template>
                        </template>
                    </th>
                    <th></th>
                    <th scope="col"><a href="#" @click="sortcol('ctime')">{{$t('main.date_order')}}</a>
                        <template v-if="filter.sort_col == 'ctime'">
                            <template v-if="filter.sort_direct == 'asc'">
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </template>
                            <template v-else>
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </template>
                        </template>
                    </th>
                    <th scope="col"><a href="#" @click="sortcol('client')">{{$t('main.customer')}}</a>
                        <template v-if="filter.sort_col == 'client'">
                            <template v-if="filter.sort_direct == 'asc'">
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </template>
                            <template v-else>
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </template>
                        </template>
                    </th>
                    <th scope="col"><a href="#" @click="sortcol('phone')">{{$t('main.phone')}}</a>
                        <template v-if="filter.sort_col == 'phone'">
                            <template v-if="filter.sort_direct == 'asc'">
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </template>
                            <template v-else>
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </template>
                        </template>
                    </th>
                    <th scope="col"><a href="#" @click="sortcol('owner')">{{$t('main.operator')}}</a>
                        <template v-if="filter.sort_col == 'owner'">
                            <template v-if="filter.sort_direct == 'asc'">
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </template>
                            <template v-else>
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </template>
                        </template>
                    </th>
                    <th scope="col"><a href="#" @click="sortcol('operator')">{{$t('main.operator_opp')}}</a>
                        <template v-if="filter.sort_col == 'operator'">
                            <template v-if="filter.sort_direct == 'asc'">
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </template>
                            <template v-else>
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </template>
                        </template>
                    </th>
                    <th scope="col">{{$t('main.status_order')}}</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in orders">
                    <td>
                        <!--{{(pageNumber-1)*size + index + 1}}-->
                      <b-form-checkbox
                          v-model="item.checked"
                          @change="addSelectItem(item, $event)"

                      />
<!--                        <input type="checkbox" v-model="item.checked" @change="addSelectItem(item, $event)"/>-->
                    </td>
<!--                    <td v-if="mode_union">-->
<!--                        <input type="checkbox" @change="addUnionItem(item, $event)" />-->
<!--                    </td>-->
                    <td>{{item.number}}</td>
                    <td class="text-left">
                        <template v-if="item.order_not_create">
                            <button class="btn my-btn" size="sm" v-b-tooltip.hover :title="$t('main.waiting_for_order_to_created')"><b-icon-arrow-bar-up variant="primary" ></b-icon-arrow-bar-up></button>&nbsp
                        </template>
                        <template v-if="item.etherid > 0">
                            <button class="btn my-btn" size="sm" v-b-tooltip.hover :title="$t('main.ether_name', {name: item.ether_name})"><b-icon-tv variant="success" ></b-icon-tv></button>&nbsp
                        </template>
                        <template v-if="item.revision">
                            <button class="btn my-btn" size="sm" v-b-tooltip.hover :title="$t('main.needs_improvement')"><b-icon-exclamation-diamond variant="danger" ></b-icon-exclamation-diamond></button>&nbsp
                        </template>
                        <template v-if="item.union_link!=''">
                            <button  class="btn my-btn" size="sm" v-b-tooltip.hover :title="$t('main.see_order') + ' ' + item.union_link " @click="showOrder({ etherid: item.union_link.etherid, orderid: item.union_link.orderid, operator_id: 1 })">
                                <b-icon-at variant="danger" ></b-icon-at>
                            </button>&nbsp
                        </template>
                        <template v-if="item.club_card!=''">
                            <button class="btn my-btn" size="sm" v-b-tooltip.hover :title="'Card valid until ' + item.club_card">
                                <b-icon-credit-card variant="success"></b-icon-credit-card>
                            </button>&nbsp
                        </template>

                    </td>
                    <td>{{item.ctime}}</td>
                    <td>{{item.client}}</td>
                    <td>{{item.phone}}</td>
                    <td>{{item.owner_name}}</td>
                    <td>{{item.operator}}</td>
                    <td>{{item.status}}</td>
                    <td>
                        <button class="btn btn-sm btn-success"  @click="showOrder(item)">
                            <b-icon-pencil-square></b-icon-pencil-square>
                        </button>
                      <button class="btn btn-sm btn-info ml-1"  @click="showModalHistory(item.etherid+'-'+item.orderid)">
                        <b-icon-clock-history></b-icon-clock-history>
                      </button>
                    </td>
                </tr>
                </tbody>
            </table>
            <br/>
            <br/>
        </b-overlay>
      <!--Order :bus="localbus" @close="loadData()" /-->
      <b-navbar fixed="bottom" type="light" variant="light" style="border-top: 1px solid rgba(0, 0, 0, 0.1);" v-if="items_select.length > 1">
            <b-dropdown :text="$t('opp.change_status')" variant="success" class="m-md-2" dropup >
                <b-dropdown-item v-for="item in options_status" :key="item.value" :data-value="item.value" :disabled="item.disabled" @click="changeStatus(item.value)">{{item.text}}</b-dropdown-item>
            </b-dropdown>
          <b-button v-if="unionRequirements" v-b-modal.modal_unite_orders variant="info">{{$t('opp.unite_orders')}}</b-button>
      </b-navbar>
      <b-modal
          size="lg"
          id="modal_unite_orders"
          :title="$t('opp.unite_orders')"
          no-close-on-backdrop
          @show="showUnionOrderData()"
          :ok-title="$t('main.update')"
          :cancel-title="$t('main.cancel')"
          hide-header-close
          :ok-disabled="false"
          @ok="handleUniteOrders()"
      >
        <b-form-group :label="$t('opp.select_main_order')">
          <b-form-select v-model="unite_orders.mainOrder" :options="unionMainOrderOptions"></b-form-select>
        </b-form-group>
      </b-modal>
    </div>
</template>

<style>
    .my-btn {
        padding: 0px !important;
    }
</style>

<script>
    import Vue from 'vue';
    import Axios from 'axios';
    import bus from "@/bus";
    import statusForm from "@/components/statusForm.vue";
    import RegionForm from "@/components/regionForm.vue";
    import ProductOneForm from "@/components/productOneForm.vue";

export default {
        name: 'OPP',
        components: {
//            Order,
            statusForm,
            RegionForm,
            ProductOneForm
        },

        data() {
            return {
                orders: [],
                new_order: 0,
                missed_order: 0,
                loading: false,
                pageNumber: 1,
                filtredDataCount: 0,
                show_filter: true,
                // mode_union: false,
                unionMainOrderOptions: [],
                unionMethodOptions: [],
                unite_orders: {
                  mainOrder: '',
                  method: ''
                },
                items_select: [],
                options_status: [],
                rnames:   '',
                regions: [],
                product_title: '',
                filter: {
                    regions:            [],
                    date_min_value:     '',
                    date_max_value:     '',

                    panel_datepick:     false,
                    panel_search:       false,

                    s_filter_time:      'today',
                    s_filter_custom:    'none',
                    s_filter_owner:     '',
                    s_filter_operator:  '',
                    s_filter_status:    'new-order',
                    s_filter_order_id:  '',
                    s_filter_needs_improvement: false,
                    s_filter_product: '',
                    sort_col:           'client',
                    sort_direct:        'desc'
                },
                so: {
                    options_time: [
                        {value: 'all', text: this.$t('main.show_all')},
                        {value: 'today', text: this.$t('main.today')},
                        {value: 'yesterday', text: this.$t('main.yesterday')},
                        {value: 'days7', text: this.$t('main.in_7_days')},
                        {value: 'days30', text: this.$t('main.current_month')},
                        {value: 'manual', text: this.$t('main.period')},
                    ],
                    options_status: [],
                    options_operators: [],
                    options_owners: []
                },
//                localbus: new Vue(),
//                localbus_c: new Vue()
            }
        },

        metaInfo() {
            return {
                title: this.$t('opp.title')
            }
        },

        mounted() {
            let _this = this;
            this.filter.date_min_value   = new Date().toISOString().slice(0, 10);
            this.filter.date_max_value   = new Date().toISOString().slice(0, 10);

            bus.$emit('set-header', this.$t('opp.title'));

            this.opp_statuses.forEach(function(item){
                _this.options_status.push(item);
            });

            if (typeof localStorage.filter_opp !== 'undefined') {
                this.filter = JSON.parse(localStorage.filter_opp);
            }
            this.loadData();
        },

        props: {
            size: {
                type: Number,
                required: false,
                default: 20
            }
        },

        methods: {
            addSelectItem(item, e){
                let _this = this;
                if (item === null) {
                    this.orders.forEach(function(obj){
                        if (e) {
                            _this.items_select.push(obj);
                            obj.checked = true;
                        }else{
                            obj.checked = false;
                        }
                    });
                    if (!e) this.items_select = [];
                }else {
                    let uid = item.etherid + '-' + item.id;
                    if (e) {
                        this.items_select.push(item);
                    } else {
                        let index = this.items_select.map(function (obj) {
                            return obj.etherid + '-' + obj.id;
                        }).indexOf(uid);

                        this.items_select.splice(index, 1);
                    }
                }

                this.viewStatusOptions();
            },

            showModalHistory(order) {
                bus.$emit('portal:order:history:open', order);
            },

            viewStatusOptions(){
                let arr = [];
                this.items_select.forEach(function (item){

                    item.allow.forEach(function (item2){
                        arr.push(item2);
                    });
                    arr.push(item.status_id);
                });
                let res = this.unique_array(arr, this.items_select.length);
                this.options_status.forEach(function (item){
                    let key = item.value;
                    item.disabled = !(res.has(key) || key == '');
                });
//                console.log(this.options_status);
            },

            changeStatus(status){
                let items = [];
                this.orders.forEach(function(obj){
                    if (obj.checked) {
                        items.push(obj.etherid + '-' + obj.id);
                    }
                });

                Axios
                        .post(this.prefix_url + '/api/opp?ts=' + Date.now(), {
                            method:     'change_status',
                            items:      items,
                            status:     status
                        }).then(response => {
                            if (!response.data.success) {
                                bus.$emit('view-message', {
                                    message: this.$t('main.error_toast'),
                                    variant: 'success'
                                });
                            }else{
                                this.items_select = [];
                                this.loadData();
                            }
                });
            },

            showOrder(data) {
                bus.$emit('portal:order:open', {
                    ether_id: data.etherid,
                    order_id: data.orderid
                });
            },

            sortcol(col){
                if (col == 'number') {
                    if (this.filter.sort_col != 'number') {
                        this.filter.sort_col      = 'number';
                        this.filter.sort_direct   = 'desc';
                    }else{
                        if (this.filter.sort_direct == 'asc') this.filter.sort_direct = 'desc';else this.filter.sort_direct = 'asc';
                    }
                }else if (col == 'ctime') {
                    if (this.filter.sort_col != 'ctime') {
                        this.filter.sort_col      = 'ctime';
                        this.filter.sort_direct   = 'asc';
                    }else{
                        if (this.filter.sort_direct == 'asc') this.filter.sort_direct = 'desc';else this.filter.sort_direct = 'asc';
                    }
                }else if (col == 'client') {
                    if (this.filter.sort_col != 'client') {
                        this.filter.sort_col      = 'client';
                        this.filter.sort_direct   = 'desc';
                    }else{
                        if (this.filter.sort_direct == 'asc') this.filter.sort_direct = 'desc';else this.filter.sort_direct = 'asc';
                    }
                }else if (col == 'phone') {
                    if (this.filter.sort_col != 'phone') {
                        this.filter.sort_col      = 'phone';
                        this.filter.sort_direct   = 'desc';
                    }else{
                        if (this.filter.sort_direct == 'asc') this.filter.sort_direct = 'desc';else this.filter.sort_direct = 'asc';
                    }
                }else if (col == 'operator') {
                    if (this.filter.sort_col != 'operator') {
                        this.filter.sort_col      = 'operator';
                        this.filter.sort_direct   = 'desc';
                    }else{
                        if (this.filter.sort_direct == 'asc') this.filter.sort_direct = 'desc';else this.filter.sort_direct = 'asc';
                    }
                }else if (col == 'owner') {
                    if (this.filter.sort_col != 'owner') {
                        this.filter.sort_col      = 'owner';
                        this.filter.sort_direct   = 'desc';
                    }else{
                        if (this.filter.sort_direct == 'asc') this.filter.sort_direct = 'desc';else this.filter.sort_direct = 'asc';
                    }
                }
                this.loadData();
            },

        loadData() {
            this.loading = true;
            Axios
                    .get(this.prefix_url + '/api/opp?ts=' + Date.now()+'&data='+JSON.stringify(this.filter)+'&page='+(this.pageNumber-1)+'&limit='+this.size)
                    .then(response => {
                        this.orders = response.data.orders;
                        this.so.options_owners = response.data.owners;
                        this.so.options_operators = response.data.operators;
                        this.so.options_status  = response.data.statuses;
                        this.filtredDataCount   = response.data.count;
                        this.new_order = response.data.new_order;
                        this.missed_order = response.data.missed_order;
                        this.items_select = [];
                        this.loading = false;
            });
        },

    resetFilter() {
        this.filter.s_filter_time        = 'today';
        this.filter.s_filter_order_id    = '';
        this.filter.s_filter_owner       = '';
        this.filter.s_filter_operator    = '';
        this.filter.s_filter_status      = 'new-order';
        this.filter.s_filter_product     = '';
        this.product_title               = '';
        this.filter.s_filter_needs_improvement    = false;
        this.filter.panel_datepick       = false;
        this.filter.panel_search         = false;
        this.filter.regions              = [];
        this.regions                     = [];
        this.rnames                      = '';

        this.changeFilter();
    },

    changeMinDate(){
        console.log('min', this.datepick_min_value, this.datepick_max_value);
        let min_value = new Date(this.datepick_min_value),
                max_value = new Date(this.datepick_max_value);
        if (min_value.getTime() > max_value.getTime()) {
            bus.$emit('view-message', { message: this.$t('main.start_date_period_cannot_be_later_than_the_end_date_period'), variant: 'danger' });
            this.datepick_min_value = this.datepick_max_value;
        }
        this.loadData();
    },

    changeMaxDate(){
        console.log('max', this.datepick_min_value, this.datepick_max_value);
        let min_value = new Date(this.datepick_min_value),
                max_value = new Date(this.datepick_max_value);
        if (max_value.getTime() < min_value.getTime()) {
            bus.$emit('view-message', { message: this.$t('main.end_date_period_cannot_be_earlier_than_the_start_date_period'), variant: 'danger' });
            this.datepick_max_value = this.datepick_min_value;
        }
        this.loadData();
    },

    showUnionOrderData () {
      let arr_obj = [];
      let arr_obj_method = [];
      let arr_ids = [];

      arr_obj_method[0] = {"text": this.$t('opp.unite_order_products'),"value":"sum"};
      for (let j = 0; j < (this.items_select.length); j++) {
        let arr = {};
        arr['text'] = this.$t('main.order_id') + " " + this.items_select[j].number + " (" + this.items_select[j].owner_name + ")";
        arr['value'] = this.items_select[j].etherid+'-'+this.items_select[j].id;

        arr_ids.push(this.items_select[j].etherid+'-'+this.items_select[j].id);

        arr_obj.push(arr);
        arr_obj_method.push(arr);

      }
      this.unite_orders.method = "sum";
      this.unite_orders.mainOrder = arr_obj[0]['value'];
      this.unite_orders.ids = arr_ids;
      this.unionMainOrderOptions = arr_obj;
      this.unionMethodOptions = arr_obj_method;
    },


    handleUniteOrders () {
      let data = this.unite_orders;
      Axios
          .post(this.prefix_url + '/api/opp?ts=' + Date.now(), {
            method:     'unite',
            data: data
          }).then(response => {
        if (!response.data.success) {
          bus.$emit('view-message', {
            message: this.$t('main.error_toast'),
            variant: 'danger'
          });
        }else{
          this.items_select = [];
          this.loadData();
        }
      });
    },

    changeFilter(){
        if (this.filter.s_filter_custom === 'none') {
            this.filter.panel_datepick = this.filter.s_filter_time === 'manual';
            this.loadData();
        }
        if (this.pageNumber !== 1) {
            this.pageNumber = 1;
            this.loadData();
        }

        // this.mode_union  = false;
        // this.items_union = [];
    },

    goPage(n) {
        this.pageNumber = n;
        this.loadData();
        // this.mode_union  = false;
        // this.items_union = [];
    },

    addStatus(data) {
        this.user_rules.push({ id: data.id, name: data.name });
    },

    delStatus(data) {
        let index = this.user_rules.map(function (item) {
            return item.id;
        }).indexOf(data.id);

        this.user_rules.splice(index, 1);
    },

    unique_array(arr, n){
        let res = new Map();
        arr.forEach(function (item) {
            if (res.has(item)) {
                let val = res.get(item) + 1;
                res.set(item, val);
            } else {
                res.set(item, 1);
            }
        });

        for (let pair of res.entries()) {
            if (pair[1] != n) res.delete(pair[0]);
        }
        return res;
    },
    addRegion(data) {
        this.regions.push({ id: data.id, name: data.name });
        this.filter.regions = this.regions;
        this.loadData();
    },

    delRegion(data) {
        let index = this.regions.map(function (item) {
            return item.id;
        }).indexOf(data.id);

        this.regions.splice(index, 1);
        this.filter.regions = this.regions;
        this.loadData();
    },

    setFilterProduct(data){
        this.filter.s_filter_product = data.id;
        this.product_title = data.name_s;
        this.loadData();
    }
    },

    computed : {
        rule_order_hopshop: function (){return this.$store.getters.getRule('order') },
        rule_order_zargar: function (){return this.$store.getters.getRule('orderzargar') },
        rule_order_lots: function (){return this.$store.getters.getRule('orderlots') },
        rule_order_search: function (){return this.$store.getters.getRule('orderSearch') },
        rule_order_create_ticket: function (){return this.$store.getters.getRule('ticketCreate') },
        rule_admin: function (){return this.$store.getters.getRule('tv_admin') },

        pageCount() {
            let l = this.filtredDataCount,
                    s = this.size;
            if (this.filtredDataCount===0) return 1;

            return Math.ceil(l / s);
        },

        unionRequirements () {
            for (let j = 1; j < this.items_select.length; j++) {
              if (!(this.items_select[j].phone === this.items_select[j - 1].phone && this.items_select[j].etherid === this.items_select[j - 1].etherid)) {
                return false
              }
            }
            return true
        },

        current_phone: {
            get () { return this.$store.getters.getCurrentPhone; },
            set(value) { this.$store.dispatch('setCurrentPhone', value); }
        },
        prefix_url:function () { return process.env.VUE_APP_PREFIX_URL },

        opp_statuses: function () {
            let loc = this.$store.getters.getLocal;
            return loc.opp_statuses;
        },
    },

    watch: {
        datepick_max_value: function(val){
            this.changeMaxDate();
        },

        datepick_min_value: function(val){
            this.changeMinDate();
        },
        filter: {
            handler(val)
            {
              localStorage.filter_opp = JSON.stringify(this.filter);
            },
            deep: true
        }
    }
    }
</script>