import Vue from 'vue'
import Router from 'vue-router'

import store from '../store.js';

import Main from '../views/Main.vue'
import Login from '../components/Login.vue'
import DenyAccess from '../components/DenyAccess.vue'
import Orders from '../views/Orders.vue'
import Order from '../views/Order.vue'
import OrderLots from '../views/OrderLots.vue'
import OrderSearch from '../views/OrderSearch.vue'
import Tv from '../views/Tv.vue'
import OPP from '../views/OPP.vue'

Vue.use(Router)

const ifAuthenticated = (to, from, next) => {
    if (!store.getters.isLoggedIn) {
        next('/login');
        return;
    }else if (!store.getters.getRule(to.name)){
        next('/access-deny');
        return;
    }
    store.dispatch('setCView', to.name);
    next();
};


export default new Router({
  mode: 'history',
  hash: false,
  routes: [
    {
        path: '/',
        name: 'Main',
        component: Main,
        beforeEnter: ifAuthenticated
    }, {
	    path: '/login',
        name: 'login',
        component: Login
    }, {
          path: '/access-deny',
          name: 'accessdeny',
          component: DenyAccess
    }, {
	path: '/settings',
        name: 'settings',
        component: () => import('../views/Settings.vue'),
        beforeEnter: ifAuthenticated,
        children: [{
            path: 'panelhopshop',
            name: 'panelhopshop',
            component: () => import('../views/Settings/HopShopPanel.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'rules',
            name: 'rules',
            component: () => import('../views/Settings/Rules.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'manifest',
            name: 'setting-manifest',
            component: () => import('../views/Settings/ManifestRules.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'checkregistry',
            name: 'setting-checkregistry',
            component: () => import('../views/Settings/CheckRegistryRules.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'logs',
            name: 'logs',
            component: () => import('../views/Settings/Logs.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'dict-cities',
            name: 'dict-cities',
            component: () => import('../views/Settings/DictCities.vue'),
            beforeEnter: ifAuthenticated
        },
            {
                path: 'dict-regions',
                name: 'dict-cities',
                component: () => import('../views/Settings/DictRegions.vue'),
                // beforeEnter: ifAuthenticated
            },
            {
            path: 'dict-stores',
            name: 'dict-stores',
            component: () => import('../views/Settings/DictStores.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'dicts/host',
            name: 'dicts',
            component: () => import('../views/Settings/DictPresenter.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'dicts/producer',
            name: 'dicts',
            component: () => import('../views/Settings/DictProducer.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'dicts/stone',
            name: 'dicts',
            component: () => import('../views/Settings/DictStone.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'dicts/operator',
            name: 'dicts',
            component: () => import('../views/Settings/DictOperator.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'dicts/assistant',
            name: 'dicts',
            component: () => import('../views/Settings/DictAssistant.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'dicts/lineuper',
            name: 'dicts',
            component: () => import('../views/Settings/DictLineuper.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'dicts/channel',
            name: 'dicts',
            component: () => import('../views/Settings/DictChannel.vue'),
            beforeEnter: ifAuthenticated
        }, {
            path: 'dicts/studio',
            name: 'dicts',
            component: () => import('../views/Settings/DictStudio.vue'),
            beforeEnter: ifAuthenticated
        },
            {
                path: 'dicts/sources/hopshop',
                name: 'dicts',
                component: () => import('../views/Settings/DictDigtalSources.vue'),
                props: {type: 'hopshop'},
                beforeEnter: ifAuthenticated
            },{
                path: 'dicts/sources/zargar',
                name: 'dicts',
                component: () => import('../views/Settings/DictDigtalSources.vue'),
                props: {type: 'zargarshop'},
                beforeEnter: ifAuthenticated
            },{
            path: 'main',
            name: 'setting-main',
            component: () => import('../views/Settings/Main.vue'),
            beforeEnter: ifAuthenticated
        },
        {
                path: 'favorites',
                name: 'favorites',
                component: () => import('../views/Settings/Favorites.vue'),
                beforeEnter: ifAuthenticated
        }
        ]
    },

    {
	    path: '/tv',
        name: 'tvlist',
        component: () => import('../views/TvList.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/tv/:id',
        name: 'tv',
        component: Tv,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/customers',
        name: 'customers',
        component: () => import('../views/Customers.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/calls',
        name: 'calls',
        component: () => import('../views/Calls.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/hopshop',
        name:'hslist',
        component: () => import('../views/HopShopList.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/hopshop/:id',
        name: 'hslot',
        component: () => import('../views/HopShopLot.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/hopshop2',
        name: 'hslist',
        component: () => import('../views/HopShopPanel.vue'),
        beforeEnter: ifAuthenticated
    },

    {
        path: '/hopshop2/:date/:id',
        name: 'hslot',
        component: () => import('../views/HopShopPanelData.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/manifest',
        name: 'manifest',
        component: () => import('../views/Manifest.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/manifest/:id',
        name: 'manifest-item',
        component: () => import('../views/ManifestData.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/checkregistry',
        name: 'checkregistry',
        component: () => import('../views/CheckRegistry.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/checkregistry/:id',
        name: 'checkregistry-item',
        component: () => import('../views/CheckRegistryData.vue'),
        beforeEnter: ifAuthenticated
    },
    {
	    path: '/monitor',
        name: 'monitor',
        component: () => import('../views/Monitor.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/stream',
        name: 'stream',
        component: () => import('../views/Stream.vue')
    },
    {
        path: '/ethers',
        name: 'ethers',
        component: () => import('../views/EtherList.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/ethers/:id',
        name: 'ether',
        component: () => import('../views/Ether.vue'),
        beforeEnter: ifAuthenticated
    },
    {
	    path: '/orders',
        name: 'orders',
        component: Orders,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/orders/search',
        name: 'orderSearch',
        component: OrderSearch,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/orders/hopshop',
        name: 'order',
        component: Order,
        props: true,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/orders/hopshop/:id',
        name: 'order_edit',
        component: Order,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/orders/lots',
        name: 'orderlots',
        component: OrderLots,
        props: true,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/orders/lots/:id',
        name: 'orderlots_edit',
        component: OrderLots,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/opp',
        name: 'opp',
        component: OPP,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/ticket/product',
        name: 'productticket',
        component: () => import('../views/HelpDeskProductList.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/ticket/delivery',
        name: 'deliveryticket',
        component: () => import('../views/HelpDeskDeliveryList.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/ticket/:ticket_id',
        name: 'helpdeskticket',
        component: () => import('../components/HelpDeskEntity.vue'),
        props: true,
        beforeEnter: ifAuthenticated
    }
  ]
})
